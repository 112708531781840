import axios from "axios"



export default async function get_token_for_bot(bot_name){
    const res = await axios.post(
        import.meta.env.VUE_APP_CONCIERGE_BOT_HOST.concat('/get-token'),
        {
            "client_id": import.meta.env.VUE_APP_CONCIERGE_CLIENT_ID,
            "secret": import.meta.env.VUE_APP_CONCIERGE_BOT_SECRET,
            "service_to_access": bot_name
        }
    )
    return res.data['token']
}

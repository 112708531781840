<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.deleteDutyDialogData.visible"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Удалить дежурство
        </v-card-title>

        <v-card-text>
          Вы уверены?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="delDuty"
          >
             Абсолюдли
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import get_token_for_bot from '@/utils' 

  export default {
    methods:{
        delDuty: async function(){
          let token = await get_token_for_bot(import.meta.env.VUE_APP_ATTENDANTS_BOT_NAME)
          let url = import.meta.env.VUE_APP_ATTENDANTS_BOT_HOST.concat('/duty/delete/' + this.$store.state.deleteDutyDialogData.dutyId)
          axios.post(url, {},
            {
              headers: {
                'X-token': token,
                "Content-Type": "application/json",
              }
           }
          ).then(response => {
            this.$store.commit('showSnackbar', response.data.message)
          }).catch(response => {
            this.$store.commit('showSnackbar', response.response?.data?.message || 'err-code: ' + response.response?.status || 'hz')
          })
          this.$store.commit('deleteDuty', this.$store.state.deleteDutyDialogData.dutyId)
          this.$store.commit('hideDutyDelDialog')
        }
    }
  }
</script>
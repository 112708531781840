import Vuex from 'vuex'


export default new Vuex.Store({
    state: {
        user: null,

        snackbar: {
            show: false,
            text: 'hello'
        },
        deleteDutyDialogData:{
             dutyId: 0,
             visible: false,
        },
        scheduleTableDuties: [],
        DataPickerDuties: [],
    },
    mutations: {
        addUser(state, user){
            state.user = user
        },
        showSnackbar(state, text){
            if(state.snackbar.show){
                state.snackbar.show = false
            }
            state.snackbar.show = true
            state.snackbar.text = text
        },
        hideSnackbar(state){
            state.snackbar.show = false
        },
        showDutyDelDialog: function(state, id){
            state.deleteDutyDialogData.visible = true
            state.deleteDutyDialogData.dutyId = id
        },
        hideDutyDelDialog: function(state){
            state.deleteDutyDialogData.visible = false
        },
        
        deleteDuty(state, id){
            state.scheduleTableDuties = state.scheduleTableDuties.filter(
                duty => duty.customData.id != id
            )
            state.DataPickerDuties = state.DataPickerDuties.filter(
                duty => duty.id != id
            ) 
        },
        addDuty(state, duty){
            let duty_duration = 0 
            let start = new Date(duty.start)
            let duty_date = new Date(start)
            let end = new Date(duty.end)
            let DUTY_MODE_CLASSES_MAP = {
                0: 'duty-all-day',
                1: 'duty-day',
                2: 'duty-night',
            }
            let MODES_COLORS_MAP = [
                'EMPTY', 'blue', 'purple'
            ]
            // Устанавливаем соотв класс
            let clss = DUTY_MODE_CLASSES_MAP[duty.mode]

            if (end < new Date()){
                clss = 'duty-past'
            }      
            if (duty.who === state.user.mm_nick) {
                clss = clss + ' self-duty'
            }
            if (state.user.is_lead) {
                clss = clss + ' lead'
            }
            // Разбиваес дежурства длящиеся дольше дня на несколько елементов
            while (duty_date <= end){
                duty_date = new Date(start)
                duty_date.addDays(duty_duration)

                if (duty_date > end) {
                    break
                }

                duty_duration += 1

                let duty_ = {
                    key: duty.id,
                    customData: {
                        id: duty.id,
                        who: duty.who,
                        clss: clss,
                        mode: duty.mode,
                        start_time: start.getHours(),
                        end_time: end.getHours(),
                        dates: duty_date,
                    },
                    dates: duty_date,
                }
                state.scheduleTableDuties.push(duty_)
                let highlight_color = MODES_COLORS_MAP[duty.mode]
                state.DataPickerDuties = state.DataPickerDuties.map(d => {
                    if (d.dates.getDate() === duty_date.getDate() 
                    && d.dates.getMonth() === duty_date.getMonth()
                    && d.dates.getFullYear() === duty_date.getFullYear()
                     ){
                        highlight_color = 'EMPTY'
                        d.highlight = 'EMPTY'
                    }
                    return d
                })
                state.DataPickerDuties.push({
                    id: duty.id,
                    key: 'id',
                    highlight: highlight_color,
                    dates: duty_date,
                })

            }
         },
         
    },
    getters: {
        isLoggedIn(state) {
            return !!state.user && !!state.token;
        },
    },
    actions: {
    },
    modules: {
    }
})
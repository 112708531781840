<template>
    <div class="text-center section">
      <h2 class="h2"> Расписание отпусков</h2>

  <v-container>
      <v-row v-if="this.$store.state.user?.depart == 'hr'">
        <v-col
          cols="12"
          md="5"
          class="text-right"
        >
        Отдел:
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
                  label="Выбрать отдел"
                  v-model="depart"
                  :items="loadedDeparts"
                  item-title="title"
                  item-value="name"
                  single-line
                  density="compact"
                  variant="outlined"
                  >
                </v-select>
        </v-col>
      </v-row>
      <v-row v-else>
         <v-col
          cols="12"
          md="6"
          class="text-right"
        >
        Отдел:
        </v-col>
      <v-col
          cols="12"
          md="3"
          class="text-left"
        >
           {{ depart }}
      </v-col>
    </v-row>
  </v-container>

<schedule-table :depart="depart" />

  </div>
</template>

<script>
import {load_departs} from './utils'
  export default {

    async created(){
      this.loadedDeparts = ['Все', ...await load_departs()]
    },
    data () {
      
      return {
        depart: this.$store.state.user?.depart,
        loadedDeparts: [],
        attributes: [],
        employees: [],
        duty_modes: [
          {"id": 0, "title": "Сутки"},
          {"id": 1, "title": "Дневное"},
          {"id": 2, "title": "Ночное"},
        ],
        selected_employee: null,
        selected_mode: 0,
        drawer: null,

        masks: {
            weekdays: 'WWW',
        },
        // Датапикер
        dragValue: null,
        range: {
          start: new Date(),
          end: new Date(),
        },
        sendDutyBtnLoading: false,
        attrs: [
          {
            key: 'mode',
            highlight: true,
            dates: new Date(),
          },
        ],
      }
    },
    // watch: {
    //   async depart(oldDepart, newDepart){
    //     this.loadedDeparts = ['Все', ...await load_departs()]
    //   } 
    // },
    components: {
      'schedule-table': require('@/components/vacation_bot/Schedule.vue').default,
    },
  }

</script>

import { createApp } from 'vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

import './assets/styles.scss'
import store from "./store"
import vue3GoogleLogin from 'vue3-google-login'

loadFonts()

export const app = createApp(App)
  .use(router)
  .use(store)
  .use(mdiVue, {icons: mdijs})
  .use(vuetify)
  .use(vue3GoogleLogin, {
    clientId: '518568202742-tskb346qkif7b78pund2noc844ijdn6c.apps.googleusercontent.com'
  })
  .use(VueAxios, axios)
  .use(VCalendar, {})
  .use(require('vue-cookies'))
  .mount('#app')

console.log(1)
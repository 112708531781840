// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
// import colors from 'vuetify/lib/util/colors'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  // theme: {
  //   themes: {
  //   defaultTheme: 'dark',
  //     light: {
  //       primary: '#FF6E40',
  //       secondary: '#b0bec5',
  //       accent: '#8c9eff',
  //       error: '#E71D36',
  //       success: '#C5D86D',
  //       warning: '#FFC107',
  //       day: 'colors.blue.lighten3',
  //       night: 'colors.red.lighten3',
  //     },
  //   // },
  //     dark: {
  //       primary: '#ccc',
  //       secondary: '#ddd',
  //       accent: '#ccc',
  //       error: '#ddd',
  //       success: '#ccc',
  //       warning: '#ddd',
  //       day: 'red',
  //       night: 'blue',
  //     }
  //   },
  // },
})
// background-color: #ffd18b

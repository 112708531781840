import get_token_for_bot from '@/utils' 
import axios from "axios"

async function load_departs(){
    
    let token = await get_token_for_bot(import.meta.env.VUE_APP_USERS_BOT_NAME)

    let route = '/departs'

    const res = await axios.get(
        import.meta.env.VUE_APP_USERS_BOT_HOST.concat(route),
        {
        headers: {
            'X-token': token,
            "Content-Type": "application/json",
        }
        }
    );
    console.log("departs: " + res.data);
    return res.data
}

export {load_departs}
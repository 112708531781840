<template>
    {{ depart }} {{ this.$store.state.user }}
    <v-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="vacationsTableDuties"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <div
              v-for="duty in attributes"
              :key="duty.customData.id"
              class="duty text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              ref="duty"
              :dutyId="duty.customData.id"
              :color="duty.customData.color"
              :class="duty.customData.clss"
              :title="duty.customData.kind"
              @mouseover="mouseOverDuty(duty.customData.id)"
              @mouseleave="onMouseLeaveDuty(duty.customData.id)"
            > 
              <p class="who" :title="duty.customData.status">{{ this.EMOJEE_STATUSES_MAP[duty.customData.status] }} {{ duty.customData.who }}</p>
              <p class="time">{{ duty.customData.employees_comment }}</p>
              <span v-if="
                this.$store.state.user.depart === 'hr' &&
                duty.customData.head &&
                ['ОДОБРЕНО', 'ОТКЛОНЕНО', 'ОДОБРЕНО ЛИДОМ'].includes(duty.customData.status)">
                <v-btn
                  size="x-small"
                  :loading="loading[duty.customData.id]"
                  :disabled="duty.customData.status == 'ОДОБРЕНО' || loading[duty.customData.id]"
                  color="secondary"
                  @click="setStatus(duty, 'ОДОБРЕНО')"
                >
                  Одобрить
                </v-btn>
                <v-btn
                  size="x-small"
                  :loading="loading[duty.customData.id]"
                  :disabled="duty.customData.status == 'ОТКЛОНЕНО' || loading[duty.customData.id]"
                  color="warning"
                  @click="setStatus(duty, 'ОТКЛОНЕНО')"
                >
                  Отклоить
                </v-btn>
              </span>
            </div>
          </div>
        </div>
      </template>
    </v-calendar>

    <dialog-delete />
</template>

<script>
import get_token_for_bot from '@/utils' 
import axios from 'axios'
import {
  mdiDelete,
  // mdiShareVariant,
} from '@mdi/js'

  export default {
    name: 'schedule-table',
    props: ['depart'],

    data () {
      Date.prototype.addDays= function(d){
        this.setDate(this.getDate() + d);
        return this;
      }
      Date.prototype.fmtDate= function(){
        let res  = this.getDate() +'.'+ (this.getMonth() + 1) +'.'+ this.getFullYear();
        return res 
      }
      
      return {
        sendDutyBtnLoading: false,
        masks: {
            weekdays: 'WWW',
        },
        // Датапикер
        dragValue: null,
        range: {
          start: new Date(),
          end: new Date().addDays(1),
        },
        attrs: [
          {
            key: 'mode',
            highlight: true,
            dates: new Date(),
          },
        ],
        vacationsTableDuties:[],
        COLOR_CODES_CLASSES_MAP: [
            'vacation-colorcode-1',
            'vacation-colorcode-2',
            'vacation-colorcode-3',
            'vacation-colorcode-4',
        ],
        EMOJEE_STATUSES_MAP: {
          'ОДОБРЕНО': '✅',
          'ОТКЛОНЕНА ЛИДОМ': '🙅‍♂️',
          'ОДОБРЕНА ЛИДОМ': '🙋‍♂️',
          'ОТКЛОНЕНО': '❌',
          'ОЖИДАЕТ РАССМОТРЕНИЯ': '⏱',
        },
        nicknames: [],
        loading: [],
      }
    },
    async created() {
      this.loadRequests()
    },
    watch: {
      async depart(){
        this.vacationsTableDuties = new Array()
        this.loadRequests()
      } 
    },
    methods: {
        async loadRequests(){
          try {
            console.log("VBH " +  import.meta.env.VUE_APP_VACATION_BOT_HOST);
            console.log("VBN " +  import.meta.env.VUE_APP_VACATION_BOT_NAME);
            let token = await get_token_for_bot(import.meta.env.VUE_APP_VACATION_BOT_NAME)
            const res = await axios.get(
              import.meta.env.VUE_APP_VACATION_BOT_HOST.concat('/requests-for-depart/' + this.depart),
              {
                headers: {
                  'X-token': token,
                  "Content-Type": "application/json",
                }
              }
            );
            let requests = res.data['requests']
            requests.forEach(element =>{
              this.nicknames.push(element.mm_nick)
            }),
            this.nicknames = [...new Set(this.nicknames)]
            requests.forEach(element => {
              console.log("req >" + element.end_date);
                this.addRequest(element);
            });

          } catch (error) {
            console.log(error);
          }
        },
        mouseOverDuty: function(id){
            this.$refs.duty.forEach( element => {
              let duty_id = element.getAttribute('dutyId')
              if (duty_id == id){
                element.classList.add("vacation-selected")
              }
            })
        },
        onMouseLeaveDuty: function(){
          this.$refs.duty.forEach(duty => {
            duty.classList.remove('vacation-selected')
          })
        },
        delDuty: function(id){
          if (this.$store.state.user.is_lead)
          this.$store.commit('showDutyDelDialog', id)
        },
        addRequest(duty){

            let duty_duration = 0 
            let start = new Date(duty.start_date)
            let duty_date = new Date(start)
            let end = new Date(duty.end_date)

            // Устанавливаем соотв класс
            // let clss = this.REQ_KIND_CLASSES_MAP[duty.kind]
            let clss = this.COLOR_CODES_CLASSES_MAP[this.nicknames.indexOf(duty.mm_nick) % this.COLOR_CODES_CLASSES_MAP.length]
            if (end < new Date()){
                clss = 'vacation-past'
            }      
            if (duty.who === this.$store.state.user.mm_nick) {
                clss = clss + ' self-vacation'
            }
            if (this.$store.state.user.is_lead) {
                clss = clss + ' lead'
            }
            // Разбиваес дежурства длящиеся дольше дня на несколько елементов
            while (duty_date <= end){
                duty_date = new Date(start)
                duty_date.addDays(duty_duration)
    
                if (duty_date > end) {
                    break
                }
    
                duty_duration += 1
    
                let duty_ = {
                    key: duty.id,
                    customData: {
                        id: duty.id,
                        who: duty.mm_nick,
                        clss: clss,
                        kind: duty.kind,
                        status: duty.status,
                        employees_comment: duty.employees_comment,
                        start_time: start.getHours(),
                        end_time: end.getHours(),
                        dates: duty_date,
                        head: duty_duration === 1
                    },
                    dates: duty_date,
                }
                this.vacationsTableDuties.push(duty_)

            }
         },
        load (i) {
          this.loading[i] = true
          setTimeout(() => (this.loading[i] = false), 3000)
        },
        async setStatus(request, status){
          let token = await get_token_for_bot(import.meta.env.VUE_APP_VACATION_BOT_NAME)
          console.log("44 " +  import.meta.env.VUE_APP_VACATION_BOT_HOST);
          axios.post(
            import.meta.env.VUE_APP_VACATION_BOT_HOST.concat('/update-request'),
            {
              'id': request.customData.id,
              'status': status, 
              'last_editor_id': this.$store.state.user.id
            },
            {
              headers: {
                'X-token': token,
                "Content-Type": "application/json",
              }
            }
          ).then(res => {
            // request.customData.status = status
            this.vacationsTableDuties.filter(r => r.customData.id == request.customData.id).forEach(
              r => {r.customData.status = status}
            )
            // this.vacationsTableDuties.push(request)
            console.log("RREREREREE ")
            console.log(res)
          }).catch(res => console.log(res));
        }
    },
    computed: {
      selectDragAttribute() {
        return {
          popover: {
            visibility: 'hover',
            isInteractive: false, 
          },
        };
      },
    },
    icons: {
      mdiDelete,
    },
    components: {
      'dialog-delete': require('@/components/vacation_bot/Dialogs/DeleteDialog.vue').default
    },
  }
</script>
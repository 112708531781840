import { createRouter, createWebHashHistory } from 'vue-router'
import VacationBotView from '../views/VacationBotView.vue'
import store from '@/store'
import {app} from "@/main";


const routes = [
  {
    path: '/vacation-bot',
    name: 'Vacation-bot',
    component: VacationBotView
  },
  {
    path: '/attendants-bot',
    name: 'Attendants-bot',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AttendantBotView.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/tsb',
    name: 'Tsb',
    component: () => import(/* webpackChunkName: "tsb" */ '../views/TSBView.vue')
  },
]
// store.state.user?.depart === 'python-bots' ? '../views/TSBView.vue': '../views/AboutView.vue'
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // берём юзера из кук
  if (!store.state.user){
    let user = app.$cookies.get('user')
    if (user) {
      store.commit('addUser', user)
    }
  }

  if (to.name !== 'Login' && !store.state.user){
    next({ name: 'Login', params: {next: 'Tsb'} })
  } 
  else {
    if (to.name !== 'Tsb' || ['python-bots', 'moderators', 'providers'].entries(store.state.user?.depart)){
      next()
    }
  }
})
export default router

<template>
  <v-app id="inspire">
     <v-navigation-drawer 
     v-model='drawer'
     temporary
     app
     >
      <v-list-item
      :prepend-avatar="this.$store.state.user?.picture"
      >
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $store.state.user?.mm_nick }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $store.state.user?.depart }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="this.$store.state.user"
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.icon"
          :to="item.to"
          link
        >
          <v-list-item>
            <v-icon :icon="item.icon"/> 
          </v-list-item>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-app-bar-nav-icon 
      style="position: absolute;"
       v-if=this.$store.state.user
       variant="text" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      <router-view :key="$route.fullPath"></router-view>
      <snackbar/>
    </v-main>
  </v-app>
</template>

<script>
  import {
    mdiAccountGroup,
    mdiSpeedometer,
    mdiBeach,
    mdiCampfire,
    mdiTagSearchOutline,
    mdiLockOutline
  } from '@mdi/js'

  export default {
    data () {
      this.$cookies.config('1d')
      return { 
        app_title: 'Bots-UI',
        drawer: null, 
        items: [
            { title: 'Борда', icon: mdiSpeedometer, to: '/about' },
            { title: 'Дежурства', icon: mdiCampfire, to: '/attendants-bot' },
            { title: 'Отпуска', icon: mdiBeach, to: '/about' },
            { title: 'Сотрудники', icon: mdiAccountGroup, to: '/about' },
            { title: 'Поиск транзакций', icon: mdiTagSearchOutline, to: '/tsb' },
          ],
          icons: {
            mdiAccountGroup,
            mdiSpeedometer,
            mdiBeach,
            mdiCampfire,
            mdiTagSearchOutline,
            mdiLockOutline
          } 
      }
    },
    components: {
      'snackbar': require('@/components/Shared/Snackbar.vue').default
    },
    methods: {
      toggleTheme() {
        console.log("this.theme.global.current.value", this.isDark);
        this.isDark = !this.isDark
      }
    }
  }
</script>

<template>
  <div class="home">
    <VacationBotComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import VacationBotComponent from '@/components/vacation_bot/VacationBotComponent.vue'

export default {
  name: 'VacationBotView',
  components: {
    VacationBotComponent
  }
}
</script>

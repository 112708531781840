<template>
    <v-snackbar
      v-model="$store.state.snackbar.show"
    >
      {{ $store.state.snackbar.text }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="$store.commit('hideSnackbar')"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar-component',
    data() {
        return {
        }
    }

}
</script>